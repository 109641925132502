<template>
  <b-card>
    <validation-observer
      ref="accountValidation"
      tag="form"
    >
      <!-- form -->
      <b-form @submit.prevent="updateUser">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Name"
              label-for="account-username"
            >
              <b-form-input
                v-model="userDetails.first_name"
                readonly
                placeholder="Name"
                name="username"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="userEmail"
                readonly
                name="email"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required|integer|digits:10"
            >
              <b-form-group
                label="Phone Number"
                label-for="account-phone-number"
              >
                <b-form-input
                  id="phonenumber"
                  v-model="userDetails.phone_number"
                  :state="errors.length > 0 ? false : null"
                  name="phone"
                  placeholder="Phone Number"
                  type="tel"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="website"
              rules="required|url"
            >
              <b-form-group
                label="Website URL"
                label-for="website_url"
              >
                <b-form-input
                  id="website_url"
                  v-model="userDetails.website"
                  :state="errors.length > 0 ? false : null"
                  name="website url"
                  placeholder="https://yourwebsite.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="facebook"
              rules="required|url|regex:^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9._-]+\/?$"
            >
              <b-form-group
                label="Facebook Profile"
                label-for="account-facebook"
              >
                <b-form-input
                  id="facebook_profile"
                  v-model="userDetails.facebook_profile"
                  :state="errors.length > 0 ? false : null"
                  name="facebook"
                  placeholder="https://facebook.com/in/yourprofile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider
              #default="{ errors }"
              name="linkedin"
              rules="required|url|regex:^https?:\/\/(www\.)?linkedin\.com\/(.*)?$"
            >
              <b-form-group
                label="LinkedIn Profile"
                label-for="account-linkedin"
              >
                <b-form-input
                  id="linkedIn_url"
                  v-model="userDetails.linkedin_profile"
                  :state="errors.length > 0 ? false : null"
                  name="linkedin"
                  placeholder="https://linkedin.com/in/yourprofile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Time Zone"
              label-for="timezone"
            >
              <v-select
                v-model="userDetails.timezone"
                :options="timezones"
                :reduce="option => option.abbr"
                :get-option-label="option => option.text"
                placeholder="Choose Timezone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              type="submit"
              variant="primary"
              class="mt-2 mr-1 d-flex align-items-center"
            >
              <b-spinner
                v-if="loading"
                small
                class="mr-50"
              />
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BRow, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { digits, integer, regex, required, url } from '@core/utils/validations/validations' // eslint-disable-line object-curly-newline
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Timezones from './timezones.json'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BSpinner,
    BCard,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      userDetails: '',
      profileFile: null,
      timezones: Timezones.map(tz => ({
        abbr: tz.abbr,
        text: tz.text,
      })),
      userEmail: JSON.parse(localStorage.getItem('userData')).userEmail,
      required,
      url,
      digits,
      regex,
      integer,
    }
  },
  async beforeMount() {
    const result = await this.getUserData()
    const { data: { users_customuser: [userData] } } = result
    this.userDetails = {
      ...userData,
      timezone: JSON.parse(userData.timezone),
    }
  },
  methods: {
    async resetForm() {
      const result = await this.getUserData()
      const { data: { users_customuser: [userData] } } = result
      this.userDetails = userData
    },
    async updateUser() {
      try {
        // Validating the form before proceeding
        await this.validationForm()

        this.loading = true

        // Performing the mutation
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation updateProfile($id:Int!,$phone_number:String!,$timezone: String!,$facebook_profile:String!,$linkedin_profile:String!,$website:String!) {
              update_users_customuser_by_pk(
                pk_columns: { id: $id },
                _set: {
                  phone_number: $phone_number,
                  facebook_profile: $facebook_profile,
                  linkedin_profile: $linkedin_profile,
                  website: $website,
                  timezone: $timezone
                }
              ) {
                id
              }
            }
          `,
          variables: {
            id: getUserData().id,
            phone_number: this.userDetails.phone_number,
            facebook_profile: this.userDetails.facebook_profile,
            linkedin_profile: this.userDetails.linkedin_profile,
            website: this.userDetails.website,
            timezone: JSON.stringify(this.userDetails.timezone),
          },
        })

        // Updating local storage and showing toast depending upon result
        const { data: { update_users_customuser_by_pk } } = result
        this.$toast({
          component: ToastificationContent,
          props: {
            title: update_users_customuser_by_pk.id ? 'Profile updated successfully' : 'Failed to update',
            icon: update_users_customuser_by_pk.id ? 'CheckIcon' : 'XIcon',
            variant: update_users_customuser_by_pk.id ? 'success' : 'danger',
          },
        })

        localStorage.setItem('userData', JSON.stringify({
          ...getUserData(),
          timezone: this.userDetails.timezone,
        }))

        window.location.reload()
      } catch (error) {
        this.errorMessage = 'An unexpected error occured.'
      } finally {
        this.loading = false
      }
    },
    async validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountValidation.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject(new Error('Validation failed'))
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getUserData() {
      return this.$apollo.query({
        query: gql`
          query GetUserCustomerDetails($email:String!){
            users_customuser(where: { email: { _eq:$email } }) {
              id
              first_name
              phone_number
              facebook_profile
              linkedin_profile
              website
              timezone
            }
          }
        `,
        variables: {
          email: JSON.parse(localStorage.getItem('userData'))?.userEmail,
        },
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
