<template>
  <b-card>
    <validation-observer
      ref="PasswordUpdateForm"
    >
      <b-form @submit.prevent="updatePassword">
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Old Password"
              rules="required"
            >
              <b-form-group
                label="Old Password"
                label-for="account-old-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Enter Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="New Password"
              rules="required|password"
            >
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Enter New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="passwordValueOld && newPasswordValue && passwordValueOld === newPasswordValue"
                  class="text-danger"
                >Please type a new password.</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              name="Re-type New Password"
              rules="required"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Confirm New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <small
                v-if="retypePassword && newPasswordValue !== retypePassword"
                class="text-danger"
              >Passwords don't match</small>
              <small
                v-else-if="retypePassword"
                class="text-success"
              >Passwords match!</small>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              :disabled="!isFormValid"
              variant="primary"
              class="mt-1 mr-1"
            >
              Reset Password
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, password } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import { Auth } from 'aws-amplify'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      retypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isFormValid: false,
      required,
      password,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  watch: {
    passwordValueOld() {
      this.validateForm()
    },
    newPasswordValue() {
      this.validateForm()
    },
    retypePassword() {
      this.validateForm()
    },
  },
  methods: {
    async validateForm() {
      const observer = this.$refs.PasswordUpdateForm
      this.isFormValid = await observer.validate()
      const doPasswordsMatch = this.newPasswordValue === this.retypePassword
      this.isFormValid = this.isFormValid && doPasswordsMatch
    },
    updatePassword() {
      if (!this.isFormValid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please enter valid values.',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }
      Auth.currentAuthenticatedUser()
        .then(user => Auth.changePassword(user, this.passwordValueOld, this.newPasswordValue))
        .then(data => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: data === 'SUCCESS' ? 'Password updated successfully' : 'Failed to update',
              icon: data === 'SUCCESS' ? 'CheckIcon' : 'XIcon',
              variant: data === 'SUCCESS' ? 'success' : 'danger',
            },
          })
          this.passwordValueOld = ''
          this.newPasswordValue = ''
          this.retypePassword = ''
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
